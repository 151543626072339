import React, { useContext, useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'

import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField, Typography } from '@material-ui/core'
import { navigate } from "@reach/router"
import { CourseCard } from './courses'
import { withLayout } from '../components/layout'
// import Recaptcha from 'react-google-recaptcha'


import styles from "./quote.module.css"

const textOptions = ["I would like to receive a quote for the following services:",
  "ISO 9001:2015 workshop in my company",
  "ISO 9001:2015 planning and implementation consultancy",
  "ISO/TS 16949 training courses",
  "ISO/TS 16949 planning and implementation consultancy",
  "Other training (please explain in the message)",
  "Other consultancy (please explain in massage)"];

const bestTimeToContact = ["Morning",
  "Afternoon",
  "Evening",
  "Weekends"]

const Quote: React.FC = (props: PageProps<GatsbyTypes.AccountPageQuery>) => {

  const recaptchaRef = React.createRef()




  const [emailError, setEmailError] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [services, setServices] = useState({});
  const [fbestTimeToContact, setfBestTimeToContact] = useState('');
  const [message, setMessage] = useState('');

  function submit(e) {
    e.preventDefault()
    const form = e.target
    console.log(form)
    // var formData = new FormData(form)
    // console.log(formData)

    // const recaptchaValue = recaptchaRef.current.getValue()
    fetch('https://send.pageclip.co/6imEM6zYVl2AofNjK0obWkPiiaX7ewLF', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        // 'form-name': form.getAttribute('name'),
        'first-name': firstName,
        'last-name': lastName,
        'email': email,
        'company': company,
        'services': services,
        'best-time': JSON.stringify(fbestTimeToContact),
        'message': message,
        'services': JSON.stringify(services)
        // 'g-recaptcha-response': recaptchaValue,
      }),
    })
      .then(() => { alert("Thank you! You response has been submitted!"); /*navigate("/")*/ })
      .catch((error) => alert("Please contact help@leanqualityacademy.com ERROR" + error))

  }
  // console.log("serices are", services)
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Request A Quote</h1>
      <form className={styles.root} name="Request a Quote" data-netlify="true" >

        <input type="hidden" name="Request a Quote" value="contact" />



        <FormControl component="fieldset" className={styles.formControl}>
          <FormGroup>
            <TextField className={styles.textInput} id="first-name" label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            <TextField className={styles.textInput} id="last-name" label="Last Name" variant="outlined"  value={lastName} onChange={(e) => setLastName(e.target.value)} />
            <TextField className={styles.textInput} id="email" label="Email" type="email" required={true} variant="outlined"

              error={emailError}
              value={email}

              onChange={(e) =>
                {
                setEmailError(!validateEmail(e.target.value));
                setEmail(e.target.value);
              }}/>
            <TextField className={styles.textInput} id="company" label="Company" type="text" variant="outlined" />
          </FormGroup>
          <FormGroup>
            <FormLabel component="legend">I would like to receive a quote for the following services:</FormLabel>
            {textOptions.map((option, index) => {
              return (
                <FormControlLabel
                  control={<Checkbox name={option} 
                    checked={services[option] == true}
                  />}
                  label={option}
                  checked={services[option] == true}
                  onChange={(e) => {
                    let oldServices = services;
                    oldServices[option] = e.target.checked;
                    setServices({...oldServices}); // gotta make a new object so react knows to update
                  }}
                  key={option}
                />
              )
            })}
          </FormGroup>


          <FormGroup>
            <FormLabel component="legend">When is the best time to contact you?</FormLabel>
            {bestTimeToContact.map((option, index) => {
              return (
                <FormControlLabel
                  control={<Checkbox name={option} 
                    checked={fbestTimeToContact[option] == true}
                  />}
                  label={option}

                  checked={fbestTimeToContact[option] == true}
                  onChange={(e) => {
                    let oldServices = services;
                    oldServices[option] = e.target.checked;
                    setfBestTimeToContact({...oldServices}); // gotta make a new object so react knows to update
                  }}
                />
              )
            })}
          </FormGroup>
          <FormGroup>
            <TextField
              id="outlined-multiline-static"
              label="Message"
              multiline
              rows={4}
              placeholder="Message"
              variant="outlined"
              value={message}
              onChange={(e) =>
                setMessage(e.target.value)
              }
            />
          </FormGroup>

          {/* <div data-netlify-recaptcha="true"></div> */}

          <FormGroup>
            <Button variant="contained" color="primary" onClick={submit} >Submit</Button>
          </FormGroup>

          {/* <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} /> */}


        </FormControl>
      </form>
    </div>

  );

}


export default withLayout(Quote);





// export const pageQuery = graphql`
// query AccountPage {
//  allContentfulCourse {
//     edges{
//       node{
//         slug
//         title
//         image{
//           fluid(maxWidth: 1600) {
//               src
//           }
//         }
//       }
//     }
//   }
// }
// `

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
